.cards {
    background-color: #FAFBFC;
}
.cards__header {
    padding: 2rem 0 0 2rem;
    line-height: 2.5rem;
}
.cards__inner {
    display: flex !important;
    flex-wrap: wrap;
    list-style: none;
    padding: 1rem;
    width: 100%;
    margin: 0 auto;
}
.cards__link {
    display: flex !important;
    padding: 2rem;
    height: 100% !important;
}
.cards__link .cards__main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
    flex: 1;
}
.cards__link header {
    width: 100%;
}
.cards__link .cards__button {
    padding: 0 1rem 0;
}
.cards__link:hover .cards__button {
    background: #DFE1E6;
}
.cards__link:focus {
    outline: 2px solid #4C9AFF;
    outline-offset: 2px;
}
.cards__item {
    background-color: white;
    border-radius: 3px;
    box-shadow: 0 0 1px 0 rgba(23, 43, 77, 0.24);
    position: relative;
    margin: 1rem 1rem 1rem 1rem;
    float: left;
    width: calc(100% - 2rem);
}
.cards__item article {
    width: 100%;
    height: 100%;
}
@media screen and (min-width: 901px) {
    .cards__item {
        width: calc(99.9% * 0.5 - 30px);
    }
    .cards__item:nth-child(2n) {
        margin-left: 3rem;
    }
}
.cards__item a.cards__link:link, .cards__item a.cards__link:visited {
    text-decoration: none;
    color: black;
    width: 100%;
}
.cards__item--full-width {
    width: 100%;
}
@media screen and (min-width: 1095px) {
    .cards__item--third-width {
        width: calc(32% - 2rem);
    }
    .cards__item--third-width:nth-child(2n) {
        margin-left: 1rem;
    }
}
.cards__icon {
    padding: 0 2rem 1rem;
}
.cards__icon svg {
    height: 4.9rem;
    width: 3.5rem;
}
.dropdown__main-content .documentation-dropdown .cards__title-container {
    position: relative;
}
.cards__title {
    color: #333;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Noto Sans', 'Oxygen', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: -0.128px;
    line-height: 1.5;
    font-weight: 400;
    letter-spacing: -0.0096rem;
    line-height: 1.25;
    margin-top: 0;
}
.cards__body {
    color: #172B4D;
    flex: 1;
    margin-bottom: 25px;
    width: 100%;
}
.cards-single {
    display: inline-block;
    width: 100%;
}
@media screen and (min-width: 901px) {
    .cards-single {
        width: 50%;
    }
}
.cards-single .cards__item {
    width: 100%;
}