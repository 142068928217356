.footer-v2 {
    align-items: center;
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: 'Charlie Text';
}
.footer-v2__container {
    background-color: #fff;
    padding-block: 1.6rem;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    width: 100%;
    margin-inline: 2.4rem;
}
@media screen and (min-width: 768px) {
    .footer-v2__container {
        display: flex;
        max-width: 120rem;
        padding-inline: 0;
        margin-inline: 6rem;
    }
}
.footer-v2__bottom {
    width: 100%;
    max-width: 121.6rem;
    margin: auto;
    padding-block: 2.4rem;
    padding-inline: 1.6rem;
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
}
@media screen and (min-width: 360px) {
    .footer-v2__bottom {
        padding-inline: 4.4rem;
        gap: 3.2rem;
    }
}
@media screen and (min-width: 768px) {
    .footer-v2__bottom {
        padding-inline: 2.4rem;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}
.footer-v2__bottom__copyright {
    color: #505258;
    line-height: 1.8rem;
    height: 1.8rem;
    font-size: 1.4rem;
}
@media screen and (min-width: 768px) {
    .footer-v2__bottom__copyright {
        text-align: center;
    }
}
.footer-v2__bottom__links-and-language {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
}
@media screen and (min-width: 768px) {
    .footer-v2__bottom__links-and-language {
        gap: 3.2rem;
        flex-direction: row;
        align-items: center;
    }
}
.footer-v2__bottom__link {
    align-items: center;
    color: #101214;
    display: inline-flex;
}
.footer-v2__bottom__link__container {
    font-size: 1.4rem;
    line-height: 1.4rem;
    height: 1.4rem;
    text-decoration: none;
    color: #101214;
}
.footer-v2__bottom__link__container:hover {
    text-decoration: underline;
    text-shadow: none;
}
.footer-v2__bottom__link__container:visited, .footer-v2__bottom__link__container:focus {
    text-shadow: none;
}