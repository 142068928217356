.pew-pew {
    position: fixed;
    top: 50%;
    width: 150px;
    animation: fly-left 3s ease-in forwards;
}
.credits-container {
    background-color: black;
    color: white;
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    padding-top: 30vh;
    opacity: 1;
    transition: opacity 2s linear;
}
.credits-container--hidden {
    opacity: 0;
}
.credits {
    display: flex;
    flex-direction: column;
    text-align: center;
    animation: slide-up 16s linear forwards;
    animation-delay: 2s;
    position: relative;
    top: 1000px;
    height: 100vh;
    width: 100vh;
}
.credits__row {
    display: flex;
    justify-content: center;
}
.credits__heading {
    color: white;
    margin-bottom: 25px;
}
.credits__section {
    margin-bottom: 50px;
}
.credits__item {
    flex: 0.5;
    margin-bottom: 25px;
}
.credits__item--left {
    text-align: right;
}
.credits__item--right {
    text-align: left;
    margin-left: 50px;
}
@keyframes fly-left {
    0% {
        right: 0;
    }
    50% {
        top: 0;
        transform: rotate(45deg);
    }
    100% {
        right: 100%;
        top: 70%;
        transform: rotate(180deg);
    }
}
@keyframes slide-up {
    0% {
        top: 120%;
    }
    100% {
        top: -300%;
    }
}