.issue-collector__button {
    background: none;
    border: inherit;
    color: inherit;
    cursor: pointer;
    font: inherit;
    outline: inherit;
    color: #0052CC;
    text-decoration: none;
}
.issue-collector__button:hover {
    text-decoration: underline;
}