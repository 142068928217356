.skip-link {
    background-color: #fff;
    border-radius: 5px;
    color: #000;
    font-size: 16px;
    left: 0px;
    max-height: 40px;
    max-width: 200px;
    padding: 2px;
    position: absolute;
    top: -40px;
    transition: top 0.1s ease-in;
    -webkit-transition: top 0.1s ease-in;
    z-index: 1;
}
.skip-link:focus-within {
    left: 0px;
    top: 0px;
}