.topic__container {
    min-width: 65.43859649%;
}
.topic__inner-container {
    display: flex;
    flex-wrap: wrap;
}
.topic__inner-container .title {
    flex: 1 1 100%;
}
.topic__inner-container .topic__body {
    flex: 1 1 65.43859649%;
    margin-top: 0;
}
.topic__inner-container .sidebar {
    flex: 1 1 34.47368421%;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .topic__inner-container {
        display: block;
    }
}
.topic__sub-header {
    display: flex;
    align-items: center;
    margin: 0 auto 3.2rem;
    justify-content: space-between;
    min-height: 3.2rem;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .topic__sub-header {
        flex-wrap: wrap;
    }
    .topic__sub-header .deploy-select {
        padding-top: 15px;
        width: 100%;
    }
    .topic__sub-header .deploy-select > div > div {
        margin: 0 2px 0 2px;
    }
}
.topic__body {
    min-width: 65.43859649%;
    position: relative;
}
@media screen and (min-width: 1095px) {
    .topic .page-tree-expanded {
        display: block;
    }
    .topic .page-tree-expanded .sidebar {
        padding-top: 4rem;
    }
    .topic .page-tree-expanded .sidebar__section {
        padding-left: 0;
    }
    .topic .page-tree-expanded .sidebar__table-of-contents {
        display: none;
    }
}