.dropdown-container {
    padding: 0;
    margin-right: 0.8rem;
    height: 3.4rem;
}
.dropdown-container__pulse {
    border-radius: 3px;
    -moz-animation: cubic-bezier(0.55, 0.055, 0.675, 0.19) 2s infinite normal none running pulse;
    -webkit-animation: cubic-bezier(0.55, 0.055, 0.675, 0.19) 2s infinite normal none running pulse;
    animation: cubic-bezier(0.55, 0.055, 0.675, 0.19) 2s infinite normal none running pulse;
}
.dropdown-container_border {
    margin-right: 0;
    border: 1px solid #d9d9d9;
}
.dropdown-container button[aria-expanded='false'] {
    background-color: white;
    height: 3.2rem;
}
.dropdown-container button[aria-expanded='true'] {
    height: 3.2rem;
}
@keyframes pulse {
    0%, 33% {
        box-shadow: 0 0 0 2px #0052cc, 0 0 0 #0052cc;
    }
    66%, 100% {
        box-shadow: 0 0 0 2px #0052cc, 0 0 0 10px rgba(101, 84, 192, 0.01);
    }
}