.no-results-text {
    font-weight: bold;
}
.no-results-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 450px;
    margin: 0 auto;
}
.no-results-text-container .no-results-text-heading {
    font-size: 1.5em;
    margin: 0.5em 0;
    color: #172b4d;
}
.no-results-text-container .no-results-text-subheading {
    margin-bottom: 1em;
    color: #42526e;
    line-height: 1.5;
}
.no-results-text-container .support-text {
    color: #42526e;
    font-size: 0.95em;
    margin: 1em 0 0 0;
}
.no-results-text-container .support-link {
    color: #0052cc;
    text-decoration: none;
}
.no-results-text-container .support-link:hover {
    text-decoration: underline;
}