.platform-tabs {
    text-align: center;
}
.platform-tabs__tabs {
    display: flex;
    list-style: none;
    justify-content: center;
}
.platform-tabs__tabs :first-child {
    border-radius: 10rem 0px 0px 10rem;
}
.platform-tabs__tabs :last-child {
    border-radius: 0px 10rem 10rem 0px;
}
.platform-tabs__tabs :not(:last-child) {
    border-right: 1.2px solid #fff;
}
.platform-tabs__tab {
    font-family: 'Charlie Text';
    font-feature-settings: 'liga' off, 'clig' off;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #101214;
    height: 6rem;
    min-width: 16.1rem;
    max-width: 28rem;
    padding: 1.6rem 2.8rem;
    background-color: #f0f1f2;
    align-content: center;
    cursor: pointer;
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.8rem;
}
.platform-tabs__tab--selected {
    color: #fff;
    background-color: #18191a;
}
.platform-tabs__tab:focus-visible {
    outline: none;
}
.platform-tabs__tab:hover:not(.platform-tabs__tab--selected) {
    background-color: #dddee1;
}