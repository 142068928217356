.pagetree-expander {
    position: absolute;
    left: 2.3rem;
    background-color: #F4F5F7;
    border-radius: 0.3rem;
    padding: 0.2rem;
    cursor: pointer;
}
.pagetree-expander:hover {
    background-color: #DFE1E6;
}
@media screen and (min-width: 0) and (max-width: 768px) {
    .pagetree-expander {
        display: none;
    }
}
@media screen and (min-width: 1095px) {
    .pagetree-width {
        flex: 0 0 34.47368421%;
    }
}
.pagetree {
    position: fixed;
    width: calc(50% - (1140px / 2) + 30.5rem);
    min-width: 34.5rem;
    background-color: #ffff;
    box-shadow: 1rem 0 2rem 0 rgba(22, 22, 22, 0.1);
    z-index: 2;
    min-height: 100vh;
    margin-top: -2.8rem;
    padding: 2rem 2rem 0 0;
    left: 0.6rem;
}
.pagetree__inner {
    max-width: 36rem;
    float: right;
    padding-left: 7rem;
}
.pagetree__back-arrow {
    position: absolute;
    align-self: start;
    left: -4.8rem;
    background: #F4F5F7;
    border-radius: 1.5rem;
    padding: 0.25rem;
    cursor: pointer;
}
.pagetree__back-arrow:hover {
    background-color: #DFE1E6;
}
.pagetree__context {
    padding-bottom: 1.5rem;
    display: flex;
    position: relative;
}
.pagetree__icon {
    padding: 0.6rem;
    background-color: #DFE1E6;
    margin-right: 1.6rem;
}
.pagetree__title {
    color: #172B4D;
    font-size: 1.4rem;
    line-height: 2rem;
    text-decoration: none;
}
.pagetree__title:hover {
    text-decoration: underline;
}
.pagetree__subhead {
    margin: 0;
    padding: 0;
    color: #7A869A;
    font-size: 1.2rem;
    line-height: 2rem;
}
.pagetree__list {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 2rem;
}
.pagetree__list--root {
    padding-inline-start: 0;
    -moz-user-select: none;
    /* Firefox */
    user-select: none;
    height: 100vh;
    overflow-y: auto;
    padding-bottom: 120px;
    margin-left: -0.5rem;
    padding-left: 0.5rem;
}
.pagetree__item {
    list-style: none;
    position: relative;
    color: #42526E;
    margin-top: 1rem;
    margin-right: 1rem;
}
.pagetree__item a {
    color: #42526E;
    font-size: 1.4rem;
    text-decoration: none;
    padding-left: 2rem;
    display: inline-block;
}
.pagetree__item a:hover {
    text-decoration: underline;
}
.pagetree__item--current-child-list > a {
    color: #091E42;
    font-weight: 600;
}
.pagetree__item--current-item > a {
    color: #0052CC;
    font-weight: 600;
}
.pagetree__item--expanded > a {
    color: #091E42;
    font-weight: 600;
}
.pagetree__expander {
    position: absolute;
    top: -0.3rem;
    left: -0.3rem;
    cursor: pointer;
    color: #42526E;
    transition: transform 250ms, color 250ms, -webkit-transform 250ms, -moz-transform 250ms, -o-transform 250ms;
}
div.pagetree__expander--current {
    color: #0052CC;
    width: 1.2rem;
    left: 0.3rem;
}
.pagetree__expander--expanded {
    color: #091E42;
    transform: rotate(90deg);
}
.pagetree--hidden {
    display: none;
}