.feedback {
    align-items: center;
    display: flex;
    margin-top: 40px;
}
.feedback__question-text {
    font-weight: 700;
    margin-right: 1rem;
}
.feedback__no-wrapper {
    position: relative;
}
.feedback__no-button {
    margin-left: 0.2rem;
}
.feedback__dropdown {
    background-color: #fff;
    border: 1px solid #dfe1e6;
    border-radius: 3px;
    box-shadow: 0 2px 5px 1px #f4f5f7;
    display: none;
    min-width: 170px;
    padding: 10px;
    position: absolute;
    right: 0;
    top: calc(100% + 2px);
    z-index: 2;
}
.feedback__dropdown.is-open {
    display: block;
}
.feedback__dropdown label {
    align-items: center;
}
.feedback__report-a-problem {
    margin-left: 2rem;
    padding: 2px;
}
.feedback__report-a-problem:focus {
    outline: auto;
}