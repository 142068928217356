.search-results-page {
    max-width: 116.8rem;
    margin: 10rem auto 0 auto;
}
.search-results-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.search-results-summary {
    grid-area: b;
    margin-left: 0.8rem;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .search-results-summary {
        display: none !important;
    }
}
.sort-by-filter-section {
    grid-area: c;
    justify-self: end;
    margin-right: 0.8rem;
}
.sort-by-filter-text {
    padding-bottom: 0.5rem;
    font-weight: 600;
    text-transform: uppercase;
}
.search-results-grid {
    display: grid;
    grid-template-columns: 20.3% 75%;
    column-gap: 4.7%;
    margin: 0 0.8rem;
}
.search-filters {
    display: grid;
    row-gap: 2.4rem;
    height: fit-content;
}
.search-filters .reset-button {
    justify-self: center;
}
.above-search-cards-container {
    display: grid;
    grid-template-areas: 'a a' 'b c';
    margin-bottom: 5.7rem;
    row-gap: 3.3rem;
}
.content-type-tabs {
    grid-area: a;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .content-type-tabs {
        display: none !important;
    }
}
.pagination {
    display: flex;
    justify-content: center;
    margin: 15.4rem 0;
}