@font-face {
    font-family: 'CharlieSans';
    src: url('../../common/fonts/Charliesans/Charlie_Display-Regular.woff') format('woff');
    font-style: normal;
    font-weight: 400;
}
@font-face {
    font-family: 'CharlieSans';
    src: url('../../common/fonts/Charliesans/Charlie_Display-Black.woff') format('woff');
    font-style: normal;
    font-weight: 700;
}
@font-face {
    font-family: 'CharlieSans';
    src: url('../../common/fonts/Charliesans/Charlie_Display-Semibold.woff') format('woff');
    font-style: normal;
    font-weight: 500;
}
@font-face {
    font-family: 'CharlieSans';
    src: url('../../common/fonts/Charliesans/Charlie_Display-Bold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'SF Pro Text Bold';
    src: url('../../common/fonts/sf-pro/SF-Pro-Text-Bold.woff') format('woff');
}
@font-face {
    font-family: 'SF Pro Text';
    src: url('../../common/fonts/sf-pro/SF-Pro-Display-Regular.woff') format('woff');
}
/* ====================================================
Colors
======================================================= */
/* Added extra break point to allow graceful screen downsizing */
/* ====================================================
CSS RESET
======================================================= */
*, *::after, *::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}
.content-platform-support {
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Noto Sans', 'Oxygen', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
html {
    font-size: 62.5%;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Noto Sans', 'Oxygen', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    html {
        font-size: 60%;
    }
}
/* ====================================================
BASE STYLES
======================================================= */
body {
    font-size: 1.4rem;
    font-weight: 400;
    letter-spacing: -0.005em;
    color: #172B4D;
}
h1, .rtf__content h1, .adf__content h1 {
    color: #253858;
    font-family: 'CharlieSans';
    font-size: 3.6rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 4.4rem;
}
h2, .rtf__content h2, .adf__content h2 {
    font-family: 'CharlieSans';
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: -0.016rem;
    line-height: 1.2;
}
.rtf__content h2, .adf__content h2 {
    font-size: 2.4rem;
    letter-spacing: 0;
    line-height: 1.3;
    margin-top: 3rem;
}
h3, .rtf__content h3, .adf__content h3, .sac-h3 {
    color: #333;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Noto Sans', 'Oxygen', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: -0.128px;
    line-height: 1.5;
}
.rtf__content h3, .adf__content h3 {
    margin-top: 10px;
}
.rtf__content h4, .adf__content h4 {
    color: #333;
    font-size: 1.4rem;
    font-weight: 600;
    letter-spacing: -0.0084rem;
    line-height: 1.428;
    margin-top: 20px;
}
main {
    width: 100%;
}
a, .sac-a {
    color: #0052CC;
    text-decoration: none;
}
a:hover, .sac-a:hover {
    text-decoration: underline;
}
.rtf__content code, .adf__content code {
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace !important;
    font-size: 14px !important;
    line-height: 1.429 !important;
}
/* ====================================================
UTILITY STYLES
======================================================= */
.padding-top-medium {
    padding-top: 2rem;
}
.margin-top-medium {
    margin-top: 2rem;
}
.margin-top-large {
    margin-top: 3.4rem !important;
}
.margin-bottom-medium {
    margin-bottom: 2rem;
}
.margin-top-small {
    margin-top: 0.8rem;
}
.margin-bottom-small {
    margin-bottom: 0.8rem;
}
.content-wrapper {
    margin-bottom: 6.5rem;
}
.cursor-pointer {
    cursor: pointer;
}
.main-content-container {
    display: block;
    position: relative;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2.8rem;
    padding-left: 70px;
    padding-right: 70px;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .main-content-container {
        padding-left: 20px;
        padding-right: 20px;
    }
}
.title h1 {
    font-family: 'CharlieSans';
    font-size: 3.6rem;
    line-height: 44px;
    letter-spacing: 0px;
    font-weight: 500;
}
.flex-column-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 1px;
    min-height: 100vh;
}
.flex-container {
    display: flex;
}
.icon-extension svg {
    display: inline-block;
    top: 0.14em;
}
.hidden {
    display: none;
}
.hide-dropdown {
    visibility: hidden;
    position: absolute;
}
.override-button-styles {
    background: none;
    border: inherit;
    color: inherit;
    cursor: pointer;
    font: inherit;
    outline: inherit;
}
.secondary-heading {
    font-size: 2.9rem;
    font-weight: 500;
    color: #172B4D;
}
.ternary-heading {
    font-size: 2rem;
    font-weight: 500;
    color: #172B4D;
}
/* ====================================================
OVERRIDE BANNERMAN / ATLASKIT STYLES
======================================================= */
.ak-renderer-extension img {
    margin-top: 20px;
}
.rtf__content ol, .adf__content ol, .rtf__content ul, .adf__content ul {
    padding-inline-start: 40px;
    margin-top: 8px;
}
.rtf__content ul ul, .adf__content ul ul, .rtf__content ol ul, .adf__content ol ul, .rtf__content ul ol, .adf__content ul ol, .rtf__content ol ol, .adf__content ol ol {
    margin-top: 0;
}
.rtf__content dd, .adf__content dd, .rtf__content dd + dt, .adf__content dd + dt {
    margin-top: 4px;
}
.rtf__content li, .adf__content li {
    margin-top: 4px;
    line-height: 20px;
}
.rtf__content .ak-editor-panel li:not(:first-child), .adf__content .ak-editor-panel li:not(:first-child) {
    margin-top: 0;
}
.rtf__content p, .adf__content p {
    margin: 12px 0 0 0;
    line-height: 20px;
}
/* ====================================================
CAC Content Styles
======================================================= */
div.main-content-wrapper.cac-wiki-content {
    line-height: 1.42857143;
}
div.main-content-wrapper.cac-wiki-content .jira-issue.conf-macro.output-block {
    color: transparent;
}
div.main-content-wrapper.cac-wiki-content .jira-issue.conf-macro.output-block .summary, div.main-content-wrapper.cac-wiki-content .jira-issue.conf-macro.output-block .issue-placeholder {
    display: none;
}
div.main-content-wrapper.cac-wiki-content ol, div.main-content-wrapper.cac-wiki-content ul {
    padding-inline-start: 40px;
    margin-top: 1.2rem;
}
div.main-content-wrapper.cac-wiki-content .wiki-content ol ol, div.main-content-wrapper.cac-wiki-content .wiki-content ol ol ol ol ol, div.main-content-wrapper.cac-wiki-content .wiki-content ol ol ol ol ol ol ol ol, div.main-content-wrapper.cac-wiki-content .wiki-content ol ol ol ol ol ol ol ol ol ol ol {
    list-style-type: lower-alpha;
}
div.main-content-wrapper.cac-wiki-content p {
    margin: 12px 0 0 0;
}
div.main-content-wrapper.cac-wiki-content p:first-child {
    margin-top: 0;
}
div.main-content-wrapper.cac-wiki-content .expand-block .expand-block-content-wrapper {
    height: auto;
}
div.main-content-wrapper.cac-wiki-content a.expand-block-expand-button {
    color: #172B4D;
    font-weight: bold;
    pointer-events: none;
    cursor: default;
}
div.main-content-wrapper.cac-wiki-content article > :first-child {
    margin-top: 0;
}
div.main-content-wrapper.cac-wiki-content .confluenceTable div.content-wrapper {
    margin: 0;
    padding: 0;
}
div.main-content-wrapper.cac-wiki-content .table-wrap {
    width: 100%;
    overflow-x: auto;
}
div.main-content-wrapper.cac-wiki-content .table-wrap table.confluenceTable {
    margin-top: 12px;
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid #dfe1e6;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}
div.main-content-wrapper.cac-wiki-content .table-wrap table.confluenceTable th.confluenceTh, div.main-content-wrapper.cac-wiki-content .table-wrap table.confluenceTable td.confluenceTd {
    padding: 10px;
    border-style: solid;
    border-color: #dfe1e6;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-top-width: 0;
    border-left-width: 0;
}
div.main-content-wrapper.cac-wiki-content .table-wrap table.confluenceTable tr:last-child td, div.main-content-wrapper.cac-wiki-content .table-wrap table.confluenceTable tr:last-child th {
    border-bottom-width: 0;
}
div.main-content-wrapper.cac-wiki-content .table-wrap table.confluenceTable tr td:last-child, div.main-content-wrapper.cac-wiki-content .table-wrap table.confluenceTable tr th:last-child {
    border-right-width: 0;
}
div.main-content-wrapper.cac-wiki-content .table-wrap table.confluenceTable th.confluenceTh {
    background-color: #fafbfc;
    height: 40px;
    vertical-align: middle;
    padding-top: 0;
    padding-bottom: 0;
    font-weight: normal;
    color: #42526e;
}
.main-content-container div.pm-table-container {
    max-width: 100% !important;
}