.menu-primary {
    display: flex;
    gap: 4rem;
    padding: 4rem;
    align-items: flex-start;
    flex-direction: column;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .menu-primary {
        flex-direction: row;
        gap: 3.2rem;
    }
}
.menu-primary__row {
    display: flex;
    gap: 3.2rem;
    width: 100%;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .menu-primary__row {
        flex-wrap: wrap;
    }
}
.menu-primary-list-item {
    display: flex !important;
    flex-direction: column;
    flex-basis: 100%;
    gap: 0.4rem !important;
}
.menu-primary-list-item:hover {
    text-decoration: none;
}
.menu-primary-list-item:hover .menu-primary-list-item__title {
    color: #1868db;
}
.menu-primary-list-item__title {
    font-family: 'Charlie Display';
    color: #101214;
    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: 0.42px;
}
.menu-primary-list-item__description {
    font-family: 'Charlie Text';
    color: #505258;
    font-size: 1.15rem;
    font-weight: 400;
}
.invisible-item {
    visibility: hidden;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .invisible-item {
        display: none;
    }
}