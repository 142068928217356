.products-dropdown-card {
    display: flex;
    align-items: center;
    gap: unset !important;
}
.products-dropdown-card:hover span {
    color: #1868db;
}
.products-dropdown-card__container {
    display: flex;
    width: 100%;
}
.products-dropdown-card__container a:hover {
    text-decoration: none;
}
.products-dropdown-card__icon-image {
    padding: 0.4rem;
}
.products-dropdown-card__icon-image__container {
    display: flex;
    width: 4.4rem;
    height: 4.4rem;
    justify-content: center;
    align-items: center;
    align-self: baseline;
    margin-inline-end: 1.2rem;
    border-radius: 0.4rem;
    background-color: #f0f1f2;
}
.products-dropdown-card__product-title {
    font-size: 1.4rem;
    line-height: normal;
    font-family: 'Charlie Display';
    font-weight: 700;
    color: #101214;
}
.products-dropdown-card__product-title__container {
    width: 24rem;
    align-self: center;
}