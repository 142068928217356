.documented-product {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}
.documented-product__sub-header {
    display: flex;
    align-items: center;
    margin: 0 auto 3.2rem;
    justify-content: space-between;
    min-height: 3.2rem;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .documented-product__sub-header {
        flex-wrap: wrap;
    }
    .documented-product__sub-header .deploy-select {
        padding-top: 15px;
        width: 100%;
    }
    .documented-product__sub-header .deploy-select > div > div {
        margin: 0 2px 0 2px;
    }
}
.documented-product__hero {
    margin-left: auto;
    margin-right: auto;
    display: flex;
}
.documented-product__hero h2 {
    color: #42526E;
    font-weight: 400;
    letter-spacing: 0.3px;
    line-height: 28px;
}
.documented-product__hero--primary {
    width: 100%;
}
@media screen and (min-width: 1095px) {
    .documented-product__hero--primary {
        width: calc(100% - 155px);
        padding-right: 5rem;
    }
}
.documented-product__hero--secondary {
    display: none;
}
@media screen and (min-width: 1095px) {
    .documented-product__hero--secondary {
        display: block;
        width: 155px;
    }
}
.documented-product__search-container {
    max-width: 55rem;
    margin-top: 36px;
}