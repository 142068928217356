.chevron-down, .chevron-up {
    display: flex;
    align-items: center;
    cursor: pointer;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    outline: inherit;
}
.chevron-down__text, .chevron-up__text {
    color: #6b778c;
    font-weight: 500;
}