.search-result-card__card-button-wrapper {
    display: block;
    border: none;
    width: 100%;
    background: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
}
.search-result-card__result-container > * {
    margin-bottom: 4rem;
}
.search-result-card__result-container > *:last-child {
    margin-bottom: 0;
}
.search-result-card .font-weight-200 {
    font-weight: 200;
}
.search-result-card .font-weight-400 {
    font-weight: 400;
}
.search-result-card .font-weight-600 {
    font-weight: 600;
}
.search-result-card .text-gray {
    color: #44546f;
}
.search-result-card .margin-bottom-md {
    margin-bottom: 1.5rem;
}
.search-result-card .background-success {
    background-color: #22a06b;
}
.search-result-card__card-container {
    display: grid;
    grid-template-columns: 67% auto;
    column-gap: 8.5%;
    width: 100%;
    padding: 3.2rem 6.8rem;
    overflow: hidden;
    border: 1px #d9d9d9 solid;
    border-radius: 6px;
}
.search-result-card__card-container .left-section .breadcrumb {
    width: 100%;
    margin-right: 1rem;
    flex-grow: 1;
    display: inline;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    color: #626f86;
}
.search-result-card__card-container .left-section .breadcrumb .breadcrumb-element {
    text-wrap: nowrap;
    text-overflow: ellipsis;
}
.search-result-card__card-container .left-section .breadcrumb .breadcrumb-element + .breadcrumb-element::before {
    content: '/';
    margin: 0 0.5rem;
}
.search-result-card__card-container .left-section .card-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
    flex: 1;
}
.search-result-card__card-container .left-section .card-content > * {
    margin-bottom: 1.5rem;
}
.search-result-card__card-container .left-section .card-content > *:last-child {
    margin-bottom: 0;
}
.search-result-card__card-container .left-section .card-content .title-section {
    display: flex;
}
.search-result-card__card-container .left-section .card-content .title-section .card-type {
    font-size: 1.5rem;
    padding: 0 1rem 0 0;
    color: #626f86;
}
.search-result-card__card-container .left-section .card-content .title-section .card-type .label {
    margin: 0 0 0 0.5rem;
}
.search-result-card__card-container .left-section .card-content .title-section .card-title {
    font-size: 1.6rem;
}
.search-result-card__card-container .left-section .card-content .card-description {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.4;
    text-align: left;
}
.search-result-card__card-container .left-section .card-content .card-description .highlighted-text {
    background-color: #fff7d6;
    color: #44546f;
}
.search-result-card__card-container .left-section .card-content .card-url {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #626f86;
    font-size: 1.2rem;
    font-weight: 400;
}
.search-result-card__card-container .left-section .card-footer {
    font-size: 1.2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.search-result-card__card-container .left-section .card-footer > * {
    margin-left: 1rem;
}
.search-result-card__card-container .left-section .card-footer > *:first-child {
    margin-left: 0;
}
.search-result-card__card-container .left-section .card-footer .answer-accepted {
    color: white;
    display: inline-flex;
    align-items: center;
    padding: 0.2rem 0.8rem 0.2rem 0.2rem;
    border-radius: 6px;
}
.search-result-card__card-container .left-section .card-footer .comments {
    display: flex;
    align-items: center;
    color: #626f86;
    font-weight: 510;
}
.search-result-card__card-container .left-section .card-footer .marked-useful {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    color: #626f86;
    font-weight: 510;
}
.search-result-card__card-container .left-section .card-footer .views-text {
    color: #626f86;
    font-weight: 510;
}
.search-result-card__card-container .right-section {
    justify-self: right;
}
.search-result-card__card-container .right-section .search-result-card__lozenge {
    max-width: 15rem;
    font-weight: 590;
    font-size: 1.2rem;
    display: inline;
    text-transform: uppercase;
}
.search-result-card__card-container .right-section .search-result-card__lozenge-container {
    display: inline-flex;
    padding: 0.4rem;
    background: rgba(9, 30, 66, 0.06);
    border-radius: 6px;
    text-align: center;
}
.search-result-card__card-container .last-modified-date {
    font-size: 1.2rem;
    font-weight: 510;
    color: #626f86;
}
.search-result-card:hover {
    background-color: #dde0e5;
    text-decoration: none;
    border-radius: 6px;
}