.header-components {
    position: relative;
}
.subheader {
    margin-top: 5rem;
}
.header {
    background-color: #0052CC;
}
.header__mobile-nav {
    display: none;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .header__mobile-nav {
        display: flex;
        width: 60vw;
    }
}
@media screen and (min-width: 768px) and (max-width: 1094px) {
    .header__mobile-nav {
        display: flex;
        width: 60vw;
    }
}
.header__mobile-nav span:first-of-type {
    cursor: pointer;
}
.header__mobile-nav h2 {
    width: 100%;
    text-align: center;
    padding-left: 40px;
    margin-top: 0;
    color: #fff;
}
.header__nav-title {
    color: #fff;
    line-height: 1.42857143;
    text-decoration: none;
}
.header__nav-title .logo-container {
    display: flex;
    align-content: center;
    height: 30px;
}
.header__nav-title h2 {
    font-family: 'CharlieSans';
    line-height: 3.2rem;
    letter-spacing: 0px;
    font-size: 2.4rem;
    font-weight: 500;
    color: #fff;
}
.header__nav-title h2:hover {
    color: #fff;
}
.header__inner-container {
    display: flex;
    margin: 0 auto;
    max-width: 1280px;
}
.header__inner-content {
    min-height: 88px;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
}
@media screen and (min-width: 1095px) {
    .header__inner-content {
        padding-right: 70px;
    }
}
.header__nav-list, .header__nav-list a {
    list-style-type: none;
    color: #fff;
    font-weight: 400;
    display: flex;
    align-items: center;
}
.header__nav-list li, .header__nav-list a li {
    height: 100%;
}
.header__nav-list li:not(:last-child) .header__nav-item, .header__nav-list a li:not(:last-child) .header__nav-item {
    margin-right: 0.5rem;
}
.header__nav-list li:not(:last-child) .header__nav-item--search-icon, .header__nav-list a li:not(:last-child) .header__nav-item--search-icon {
    margin-right: 8px;
}
.header__nav-list a:link, .header__nav-list a a:link, .header__nav-list a:visited, .header__nav-list a a:visited {
    text-decoration: none;
}
.header__nav-item {
    border-radius: 3px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    padding: 0.5rem;
    height: 30px;
    outline-offset: 0;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .header__nav-item {
        margin-right: 0 !important;
    }
}
.header__nav-item.account-btn-wrapper {
    padding: 0;
}
@media screen and (min-width: 0) and (max-width: 360px) {
    .header__nav-item.account-btn-wrapper {
        display: none;
    }
}
.header__nav-item.account-btn-wrapper .account__avatar {
    width: 34px;
}
.header__nav-item.account-btn-wrapper .account__login-btn a {
    color: #fff;
}
.header__nav-item.account-btn-wrapper a {
    color: #172B4D;
}
.header__nav-item.account-btn-wrapper .new-lozenge {
    margin-left: 0.5rem;
    display: inline-flex;
}
.header__nav-item.account-btn-wrapper .heartbeat-display p, .header__nav-item.account-btn-wrapper .heartbeat-display span {
    color: #172B4D;
}
.header__nav-item--icon {
    border-radius: 50%;
}
.header__nav-item--icon svg {
    height: 22px;
    width: 22px;
}
.header__nav-item--search-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}
@media screen and (min-width: 0) and (max-width: 384px) {
    .header__nav-item--search-icon {
        display: none;
    }
}
.header__nav-item--search-icon:hover {
    background-color: transparent;
}
@media screen and (min-width: 1095px) {
    .header__nav-item--dropdown-button {
        display: none;
    }
}
.header__nav-item--waffle {
    align-self: center;
    margin-right: 1.8rem;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .header__nav-item--waffle {
        visibility: hidden;
        width: 0;
        margin-right: 0;
        padding: 0;
    }
}
@media screen and (min-width: 768px) and (max-width: 1094px) {
    .header__nav-item--waffle {
        visibility: hidden;
        width: 0;
        margin-right: 0;
        padding: 0;
    }
}
.header__nav-item > a {
    color: #fff;
    text-decoration: none;
}
.header__nav {
    align-items: center;
    display: flex;
}
.header__nav--logo {
    color: #fff;
    display: flex;
}
.header__nav--primary {
    margin-left: 1.5rem;
}
.header__nav--primary .header__nav-list .header__nav-item {
    margin-left: 0.5rem;
    padding: 0 1.5rem;
}
.header__nav--primary .header__nav-list .header__nav-item:hover {
    background-color: #0747A6;
}
.header__nav--primary .header__nav-list .header__nav-item svg {
    position: relative;
    left: 1.03rem;
    bottom: 0.03rem;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .header__nav--primary {
        visibility: hidden;
        width: 0;
    }
}
@media screen and (min-width: 768px) and (max-width: 1094px) {
    .header__nav--primary {
        visibility: hidden;
        width: 0;
    }
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .header__nav--secondary {
        display: none;
    }
}
.header__nav--secondary .contact-btn {
    line-height: 30px;
    margin-right: 1rem;
    padding: 0;
}
.header__nav--secondary .contact-btn:hover {
    background-color: #0747A6;
}
.header__nav--secondary .contact-btn a {
    padding: 0 1rem;
}
.header__nav--secondary .partner-portal-btn {
    margin-right: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
}
.header__nav--secondary .partner-portal-btn:hover {
    background-color: #0747A6;
}
.header__nav--secondary .partner-portal-btn a {
    font-weight: 500;
    color: #fff;
    padding: 0 10px;
}
.header__nav--secondary .partner-portal-btn a:hover {
    text-decoration: none;
}
.header__nav--secondary .account__login-btn {
    border-radius: 3px;
    line-height: 30px;
}
.header__nav--secondary .account__login-btn:hover {
    background-color: #0747A6;
}
.header__nav--secondary .account__login-btn a {
    display: block;
    font-weight: 500;
    color: #fff;
    padding: 0 10px;
}
.header__nav--tertiary {
    position: fixed;
    right: 25px;
    top: 25px;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .header__nav--tertiary {
        display: none;
    }
}
.header__nav--mobile {
    display: none;
    color: #fff;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .header__nav--mobile {
        display: inline-block;
    }
}
.header__nav--mobile .header-nav-item {
    border-radius: 100px;
}
.header-v2 {
    background-color: #fff;
}
.header-v2__divider {
    width: 0.1rem;
    height: 3rem;
    background-color: #0b122824;
}
.header-v2__mobile-nav {
    display: none;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .header-v2__mobile-nav {
        display: flex;
        width: 60vw;
    }
}
@media screen and (min-width: 768px) and (max-width: 1094px) {
    .header-v2__mobile-nav {
        display: flex;
        width: 60vw;
    }
}
.header-v2__mobile-nav span:first-of-type {
    cursor: pointer;
}
.header-v2__mobile-nav h2 {
    width: 100%;
    text-align: center;
    padding-left: 4rem;
    margin-top: 0;
    color: #101214;
}
.header-v2__nav-title {
    color: #101214;
    line-height: 1.42857143;
    text-decoration: none;
}
.header-v2__nav-title:hover {
    text-decoration: none !important;
}
.header-v2__nav-title [data-testid='support-header-link'] {
    text-decoration: none !important;
}
.header-v2__nav-title .logo-container {
    display: flex;
    align-content: center;
    height: 4.4rem;
    margin-right: 3.2rem;
}
.header-v2__nav-title .logo-container-redesign {
    display: flex;
    align-content: center;
    height: 2.6rem;
    margin-right: 3.2rem;
}
.header-v2__nav-title .divider {
    border-right: 1px solid #dddee1;
    height: 3.2rem;
    margin-left: 2.4rem;
    margin-right: 2.4rem;
    padding-bottom: 0.4rem;
}
.header-v2__nav-title h1 {
    font-family: 'Charlie Display';
    font-size: 2.2rem;
    font-weight: 500;
    line-height: 2.3rem;
    color: #101214;
}
.header-v2__nav-title h1:hover {
    color: #1868db;
    cursor: pointer;
}
.header-v2__nav-title h2 {
    font-family: 'Charlie Text';
    line-height: 2rem;
    letter-spacing: 0;
    font-size: 1.6rem;
    font-weight: 500;
    color: #101214;
}
.header-v2__nav-title h2:hover {
    color: #101214;
}
.header-v2__inner-container {
    display: flex;
    margin: 0 auto;
    max-width: 1280px;
}
.header-v2__inner-content {
    min-height: 8.8rem;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
}
@media screen and (min-width: 1095px) {
    .header-v2__inner-content {
        padding-right: 70px;
    }
}
.header-v2__nav-list, .header-v2__nav-list a {
    list-style-type: none;
    color: #101214;
    font-size: 1.6rem;
    font-family: 'Charlie Text';
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 1.6rem;
}
.header-v2__nav-list li, .header-v2__nav-list a li {
    height: 100%;
}
.header-v2__nav-list [data-testid='products-dropdown-button'], .header-v2__nav-list a [data-testid='products-dropdown-button'] {
    margin-right: 1.6rem;
}
.header-v2__nav-list li:not(:last-child) .header__nav-item, .header-v2__nav-list a li:not(:last-child) .header__nav-item {
    margin-right: 0.5rem;
}
.header-v2__nav-list li:not(:last-child) .header__nav-item--search-icon, .header-v2__nav-list a li:not(:last-child) .header__nav-item--search-icon {
    margin-right: 0.8rem;
}
.header-v2__nav-list a:link, .header-v2__nav-list a a:link, .header-v2__nav-list a:visited, .header-v2__nav-list a a:visited {
    text-decoration: none;
    align-items: baseline;
}
.header-v2__nav-item {
    border-radius: 0.3rem;
    cursor: pointer;
    display: inline-flex;
    font-size: 1.6rem;
    line-height: 2rem;
    font-family: 'Charlie Text';
    font-weight: 400;
    align-items: center;
    height: 3rem;
    outline-offset: 0;
}
.header-v2__nav-item:hover {
    color: #1868db;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .header-v2__nav-item {
        margin-right: 0 !important;
    }
}
.header-v2__nav-item.account-btn-wrapper {
    padding: 0;
}
@media screen and (min-width: 0) and (max-width: 360px) {
    .header-v2__nav-item.account-btn-wrapper {
        display: none;
    }
}
.header-v2__nav-item.account-btn-wrapper .account__avatar {
    width: 3.4rem;
}
.header-v2__nav-item.account-btn-wrapper .account__login-btn a {
    color: #101214;
    font-size: 1.6rem;
    font-weight: 700;
    font-family: 'Charlie Text';
}
.header-v2__nav-item.account-btn-wrapper a {
    color: #172B4D;
}
.header-v2__nav-item.account-btn-wrapper .new-lozenge {
    margin-left: 0.5rem;
    display: inline-flex;
}
.header-v2__nav-item.account-btn-wrapper .heartbeat-display p, .header-v2__nav-item.account-btn-wrapper .heartbeat-display span {
    color: #172B4D;
}
.header-v2__nav-item--icon {
    border-radius: 50%;
}
.header-v2__nav-item--icon svg {
    height: 2.2rem;
    width: 2.2rem;
}
.header-v2__nav-item--search-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
@media screen and (min-width: 0) and (max-width: 384px) {
    .header-v2__nav-item--search-icon {
        display: none;
    }
}
.header-v2__nav-item--search-icon:hover {
    background-color: transparent;
}
@media screen and (min-width: 1095px) {
    .header-v2__nav-item--dropdown-button {
        display: none;
    }
}
.header-v2__nav-item--waffle {
    align-self: center;
    margin-right: 1.8rem;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .header-v2__nav-item--waffle {
        display: none;
    }
}
@media screen and (min-width: 768px) and (max-width: 1094px) {
    .header-v2__nav-item--waffle {
        display: none;
    }
}
.header-v2__nav-item > a {
    color: #101214;
    text-decoration: none;
}
.header-v2__nav {
    align-items: center;
    display: flex;
}
.header-v2__nav--logo {
    color: #101214;
    display: flex;
}
.header-v2__nav--primary {
    margin-left: 1.5rem;
}
.header-v2__nav--primary .header__nav-list .header__nav-item {
    margin-left: 0.5rem;
    padding: 0 1.5rem;
}
.header-v2__nav--primary .header__nav-list .header__nav-item:hover {
    background-color: #0747A6;
}
.header-v2__nav--primary .header__nav-list .header__nav-item svg {
    position: relative;
    left: 1.03rem;
    bottom: 0.03rem;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .header-v2__nav--primary {
        visibility: hidden;
        width: 0;
        margin-left: 0;
    }
}
@media screen and (min-width: 768px) and (max-width: 1094px) {
    .header-v2__nav--primary {
        visibility: hidden;
        width: 0;
    }
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .header-v2__nav--secondary {
        display: none;
    }
}
.header-v2__nav--secondary .contact-btn {
    line-height: 3rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
}
.header-v2__nav--secondary .contact-btn:hover {
    background-color: #f0f1f2;
}
.header-v2__nav--secondary .partner-portal-btn {
    margin-right: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
}
.header-v2__nav--secondary .partner-portal-btn:hover {
    background-color: #0747A6;
}
.header-v2__nav--secondary .partner-portal-btn a {
    font-weight: 500;
    color: #101214;
    padding: 0 1rem;
}
.header-v2__nav--secondary .partner-portal-btn a:hover {
    text-decoration: none;
}
.header-v2__nav--secondary .account__login-btn {
    border-radius: 0.3rem;
    line-height: 3rem;
}
.header-v2__nav--secondary .account__login-btn:hover {
    background-color: #f0f1f2;
}
.header-v2__nav--secondary .account__login-btn a {
    display: block;
    font-weight: 500;
    color: #101214;
}
.header-v2__nav--tertiary {
    position: fixed;
    right: 2.5rem;
    top: 2.5rem;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .header-v2__nav--tertiary {
        display: none;
    }
}
.header-v2__nav--mobile {
    display: none;
    color: #101214;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .header-v2__nav--mobile {
        display: inline-block;
    }
}
.header-v2__nav--mobile .header-nav-item {
    border-radius: 10rem;
}