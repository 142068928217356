.featured-content {
    padding: 6.4rem 5.5rem 9.2rem 5.5rem;
    background-color: #fff;
    border-radius: 2rem;
    border: 0.1rem solid #dddee1;
}
.featured-content__header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5.6rem;
}
.featured-content__header-title {
    font-family: 'Charlie Display';
    font-weight: 700;
    font-size: 4rem;
    align-content: center;
}
.featured-content-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 3.3rem;
    row-gap: 2.4rem;
}
@media screen and (min-width: 768px) and (max-width: 1094px) {
    .featured-content-list {
        grid-template-columns: 1fr 1fr;
    }
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .featured-content-list {
        grid-template-columns: 1fr;
    }
}
.featured-content-list-item {
    padding: 0 2.4rem;
}
.featured-content-list-item__link {
    color: #1868db;
    font-family: 'Charlie Text';
    font-weight: 400;
    font-size: 1.6rem;
}