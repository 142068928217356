.subnav-with-sidebar-tabs {
    display: grid;
    grid-template-areas: 'tablist tabpanel';
    grid-template-columns: max-content;
    background-color: #f8f8f8;
    font-family: 'Charlie Display';
}
.subnav-with-sidebar-tabs__tablist {
    display: flex;
    flex-direction: column;
    color: #101214;
    padding-inline-start: 4rem;
}
.subnav-with-sidebar-tabs__tablist__container {
    grid-area: 'tablist';
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #f8f8f8;
    padding-block-start: 4rem;
    height: max-content;
    width: 22.4rem;
    gap: 8rem;
}
.subnav-with-sidebar-tabs__tabpanel {
    grid-area: 'tabpanel';
    background-color: #fff;
    position: relative;
}
.subnav-with-sidebar-tabs__tabpanel__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 3.2rem;
    padding-inline: 3.2rem 4rem;
    padding-block: 4rem;
}
.subnav-with-sidebar-tabs__tab {
    display: flex;
    padding-inline-start: 1.6rem;
    padding-inline-end: 3.2rem;
    padding-block-start: 1.2rem;
    padding-block-end: 1.2rem;
    font-size: 1.4rem;
    font-weight: 700;
    cursor: pointer;
    border-radius: 0.5rem 0 0 0.5rem;
    width: 184px;
}
.subnav-with-sidebar-tabs__tab[aria-selected='true'] {
    background-color: #fff;
}