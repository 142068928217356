.deployment-selector__version-item {
    display: block;
    padding-left: 1rem;
    padding-right: 1rem;
}
.deployment-selector__version-item span {
    color: #505F79;
}
.deployment-selector__heading {
    padding-left: 1rem;
    padding-right: 1rem;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 600;
}
.deployment-selector__heading span {
    color: #505F79;
}
.dropdown-deployment-group button[aria-expanded='false'] {
    height: 3.2rem;
}
.dropdown-deployment-group button[aria-expanded='true'] {
    height: 3.2rem;
}