.product-card {
    height: 100%;
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3.2rem 3.6rem;
    background-color: #fafbfc;
    text-decoration: none !important;
    max-height: 195px;
}
.product-card:hover {
    background-color: #dde0e5;
}
.product-card .product-title-text {
    margin-top: 1.6rem;
    text-align: center;
    color: #44546f;
}
.product-card .icon-image {
    display: flex;
    padding: 0.6rem 0.85rem;
    justify-content: center;
    align-items: center;
}
.product-card-v2 {
    height: 100%;
    display: flex;
    min-width: 16.8rem;
    min-height: 20rem;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    padding: 2.4rem;
    border-radius: 2rem;
    background: #f8f8f8;
    box-shadow: 0px 0px 1px 0px rgba(30, 31, 33, 0.31), 0px 1px 1px 0px rgba(30, 31, 33, 0.25);
}
.product-card-v2:hover {
    text-decoration: none;
    background-color: #dde0e5;
}
.product-card-v2 .product-title-text {
    color: #091e42;
    text-align: center;
    margin-top: 2.4rem;
    font-family: 'Charlie Display';
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2.6rem;
}
.product-card-v2 .icon-image {
    display: flex;
    padding: 1rem;
    justify-content: center;
    align-items: center;
}
.product-list-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 1rem 1rem;
    margin: 5.4rem 0 2.5rem;
    list-style: none;
}
@media screen and (min-width: 768px) and (max-width: 1094px) {
    .product-list-wrapper {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .product-list-wrapper {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (min-width: 0) and (max-width: 360px) {
    .product-list-wrapper {
        grid-template-columns: 1fr;
    }
}
.product-list-wrapper li {
    height: unset !important;
}
.product-list-wrapper-v2 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 3.2rem;
    margin: 4.8rem 0 3.2rem;
    list-style: none;
}
@media screen and (min-width: 768px) and (max-width: 1094px) {
    .product-list-wrapper-v2 {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .product-list-wrapper-v2 {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (min-width: 0) and (max-width: 360px) {
    .product-list-wrapper-v2 {
        grid-template-columns: 1fr;
    }
}
.products-toggle__button:hover {
    text-decoration: none;
}
.products-toggle__button:hover .products-toggle__text {
    color: #1558bc;
}
.products-toggle__text {
    font-family: 'Charlie Text';
    color: #1868db;
    display: flex;
    align-items: center;
    height: 2.4rem;
    line-height: 2rem;
    font-size: 1.6rem;
    font-weight: 500;
}