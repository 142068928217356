.sidebar {
    display: flex;
    flex: 1 1 34.47368421%;
    flex-direction: column;
    min-width: 34.47368421%;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .sidebar {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 50px;
    }
}
.sidebar .sidebar__link {
    letter-spacing: -0.005em;
}
.sidebar__section {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;
    padding-left: 4rem;
    list-style: none;
}
.sidebar__section--topic .sidebar__item {
    display: flex;
    padding-top: 0.75rem;
}
.sidebar__section--topic .sidebar__item--show-more-up {
    margin-top: 1rem;
}
.sidebar__section--topic .sidebar__item .document-icon-wrapper > span {
    height: 20px;
    width: 20px;
}
.sidebar__section--topic .sidebar__link {
    margin-top: 0;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .sidebar__section {
        padding-left: 0;
        margin-right: 10px;
    }
    .sidebar__section--hidden-mobile {
        display: none;
    }
}
.sidebar__item.sidebar__item--current {
    text-decoration: none;
    display: list-item;
    list-style-type: disc;
    list-style-position: outside;
    font-weight: 600;
    font-size: 14px;
    line-height: 2rem;
    letter-spacing: 0.5px;
    color: #0052CC;
}
.sidebar__item.progress-bar {
    display: block;
    position: relative;
    padding-left: 30px;
}
.sidebar__item.progress-bar::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: -webkit-calc(46%);
    top: -moz-calc(46%);
    top: calc(50% - 4px);
    height: 8px;
    width: 8px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background-color: #A5ADBA;
}
.sidebar__item.progress-bar.progress-bar__visited::before, .sidebar__item.progress-bar.progress-bar__current::before {
    left: 0;
    top: 0;
    background-color: #0052CC;
    height: 100%;
}
.sidebar__item.progress-bar.progress-bar__visited::before {
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
}
.sidebar__item.progress-bar.progress-bar__visited:nth-of-type(2)::before {
    -webkit-border-top-left-radius: 5px;
    -moz-border-top-left-radius: 5px;
    border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-top-right-radius: 5px;
    border-top-right-radius: 5px;
}
.sidebar__item.progress-bar.progress-bar__current::before {
    -webkit-border-top-left-radius: 0px;
    -moz-border-top-left-radius: 0px;
    border-top-left-radius: 0px;
    -webkit-border-top-right-radius: 0px;
    -moz-border-top-right-radius: 0px;
    border-top-right-radius: 0px;
}
.sidebar__item.progress-bar.progress-bar__current:nth-of-type(2)::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: -webkit-calc(46%);
    top: -moz-calc(46%);
    top: calc(50% - 4px);
    height: 8px;
    width: 8px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background-color: #0052CC;
}
.sidebar__link {
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 2rem;
    letter-spacing: 0.5px;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
}
.sidebar__item.progress-bar__current .sidebar__link {
    font-weight: 600;
}
.sidebar__item--current .sidebar__link {
    font-weight: 600;
}
.sidebar__heading {
    display: block;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.25;
    text-decoration: none;
    color: #42526e;
}
.progress-bar .sidebar__heading {
    margin-bottom: 1rem;
}