.account-items-container {
    padding: 6px 16px;
    box-sizing: border-box;
    width: 214px;
}
.account-items-container > .menu-header {
    font-family: SF Pro Text Bold;
    height: 36px;
    background: #ffffff;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #6b778c;
}
.user-details {
    display: flex;
    margin-left: 16px;
}
.user-info {
    margin-left: 8px;
}
.user-info > .user-name {
    font-size: 14px;
    line-height: 20px;
    display: flex;
    color: #172b4d;
}
.user-info > .user-email {
    font-size: 11px;
    line-height: 14px;
    display: flex;
    color: #6b778c;
}
.menu-item {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-family: 'Charlie Display' !important;
    font-weight: 400 !important;
    align-items: center;
    color: #172b4d;
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.menu-item .lozenge-new {
    margin-left: 8px;
}
.separator {
    height: 2px;
    background: rgba(9, 30, 66, 0.08);
    flex: none;
}
.menu-shortcut {
    color: #6b778c;
}