.resource-card {
    display: flex;
    width: 36.8rem;
    min-height: 24rem;
    align-items: flex-start;
    border-radius: 2rem;
    background: #fff;
    box-shadow: 0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 1px 1px 0px rgba(9, 30, 66, 0.25);
    padding: 2.4rem;
}
.resource-card__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
}
.resource-card__content--image-container {
    display: flex;
    width: 12rem;
    height: 12rem;
    justify-content: left;
    align-items: center;
}
.resource-card__content--image-container--img {
    flex-shrink: 0;
}
.resource-card__content .card-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.4rem;
    align-self: stretch;
    margin-bottom: 2.4rem;
}
.resource-card__content .card-body .card-description {
    align-self: stretch;
    font-family: 'Charlie Text';
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.4rem;
    color: #101214;
    width: 100%;
}
.resource-card__content .card-body .card-title-text {
    align-self: stretch;
    text-align: left;
    font-family: 'Charlie Display';
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 3rem;
    color: #101214;
}
.resource-card__content--link {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    margin-top: auto;
}
.resource-card__content--link .card-link-text {
    color: #1868db;
    font-family: 'Charlie Text';
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2rem;
}
.resource-card__content--link .card-link-icon {
    padding-top: 0.1rem;
    width: 1.6rem;
    height: 1.6rem;
}
.resource-card__content--link .card-link-icon-hover {
    padding-top: 0.1rem;
    width: 1.6rem;
    height: 1.6rem;
    padding-left: 0.3rem;
}