.up-next-container {
    margin-top: 35px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    background-color: #FAFBFC;
    display: inline-block;
    width: 65.43859649%;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .up-next-container {
        width: 100%;
    }
}
.up-next-container .up-next__header {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.up-next-container .up-next__header-text-container {
    display: block;
    flex-grow: 1;
}
.up-next-container .up-next__header-text {
    font-family: 'CharlieSans';
    display: inline-block;
    flex-grow: 1;
    height: 35px;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    padding-left: 18px;
}
.up-next-container .up-next__header-text--more {
    font-family: 'CharlieSans';
    display: inline-block;
    flex-grow: 1;
    height: 35px;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    padding-left: 18px;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .up-next-container .up-next__header-text--more {
        margin-left: -20px;
        padding-right: 10px;
    }
}
.up-next-container .up-next__next-article-button {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 18px;
    border-radius: 30px;
    cursor: default;
    color: #B3BAC5;
    opacity: 0.5;
    padding-bottom: 20px;
}
.up-next-container .up-next__next-article-button--active {
    padding-right: 18px;
    color: #B3BAC5;
    opacity: 1;
    cursor: pointer;
}
.up-next-container .up-next__next-article-button--active:hover {
    text-decoration: none;
    color: #97A0AF;
}
.up-next-container .up-next__last-article-button {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 18px;
    border-radius: 30px;
    cursor: default;
    color: #B3BAC5;
    opacity: 0.5;
}
.up-next-container .up-next__last-article-button--active {
    padding-right: 18px;
    color: #B3BAC5;
    opacity: 1;
    cursor: pointer;
}
.up-next-container .up-next__last-article-button--active:hover {
    text-decoration: none;
    color: #97A0AF;
}
.up-next-container .up-next__also-in {
    column-count: 2;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .up-next-container .up-next__also-in {
        column-count: 1;
    }
    .up-next-container .up-next__also-in li {
        padding: 10px;
        margin-left: -20px;
    }
}
.up-next-container .up-next__also-in li {
    display: inline-block;
    list-style: none;
    padding: 20px;
}
.up-next-container .up-next__also-in ul {
    padding-bottom: 10px;
}
.cards.margin-top-medium {
    position: relative;
}
.cards__title {
    font-weight: 500;
    font-size: 2rem;
}