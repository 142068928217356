.notice-hub-container > section {
    min-width: 30%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    display: inline-flex;
    padding: 2rem;
    margin-bottom: 2rem;
}
.notice-hub-container > section h2 {
    margin-bottom: 1rem;
    font-size: 1.4rem;
}