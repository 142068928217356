.button {
    font-family: 'Charlie Text';
    text-decoration: none !important;
    font-weight: 500;
    border-radius: 4rem;
    border: 0.2rem solid #101214;
    padding: 0.8rem 2.4rem;
}
.button__primary {
    background-color: #1868db;
    color: #fff;
}
.button__primary:hover {
    background-color: #123263;
}
.button__secondary {
    background-color: #fff;
    color: #101214;
}
.button__secondary:hover {
    background-color: #f0f1f2;
}
.button__small {
    font-size: 1.6rem;
}
.button__large {
    font-size: 2.4rem;
}