.documentation-collection {
    margin-top: 2.3rem;
    padding-left: 2rem;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .documentation-collection {
        padding-left: 0;
    }
}
.documentation-collection h2, .documentation-collection h3 {
    font-weight: 500;
}
.documentation-collection a, .documentation-collection p {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: normal;
}
.documentation-collection__child-list {
    list-style-type: none;
}
.documentation-collection__child-list li {
    font-size: 14px;
    font-weight: 500;
    margin-top: 0.8rem;
}
.documentation-collection__child-list .documentation-collection__show-more {
    margin-top: 0.4rem;
}
.documentation-collection__list {
    margin-top: 2rem;
}
.documentation-collection__teaser {
    color: #172b4d;
    font-size: 1.4rem;
    margin-top: 0.6rem;
    margin-bottom: 1.2rem;
}