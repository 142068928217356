.topic-set__container {
    min-width: 65.43859649%;
}
.topic-set__sub-header {
    display: flex;
    align-items: center;
    margin: 0 auto 3.2rem;
    justify-content: space-between;
    min-height: 3.2rem;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .topic-set__sub-header {
        flex-wrap: wrap;
    }
    .topic-set__sub-header .deploy-select {
        padding-top: 15px;
        width: 100%;
    }
    .topic-set__sub-header .deploy-select > div > div {
        margin: 0 2px 0 2px;
    }
}
.topic-set__body {
    min-width: 65.43859649%;
}
.topic-set__body .topic__body {
    padding-top: 3rem;
    position: relative;
    width: calc(100% + 50px);
    padding-left: 50px;
    left: -50px;
}
.topic-set__body .topic__body:hover .author-tools__editor-icons {
    display: block;
}
.topic-set__body .topic__body:first-child {
    padding-top: 0;
}
.topic-set__body .topic__body:first-child h2 {
    margin-top: 0;
}
.topic-set__body .topic__body .author-tools__editor-icons {
    display: none;
}
.topic-set__sub-content {
    display: flex;
    flex-wrap: wrap;
}
.topic-set__sub-content .title {
    flex: 1 1 100%;
}
.topic-set__sub-content .topic-set__body {
    flex: 1 1 65.43859649%;
}
.topic-set__sub-content .sidebar {
    flex: 1 1 34.47368421%;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .topic-set__sub-content {
        display: block;
    }
}
.topic-set__sub-content .cards__item {
    flex: 1 100%;
    margin-left: 1rem;
    width: unset;
}
@media screen and (min-width: 1095px) {
    .topic-set .page-tree-expanded {
        display: block;
    }
    .topic-set .page-tree-expanded .sidebar {
        padding-top: 4rem;
    }
    .topic-set .page-tree-expanded .sidebar__section {
        padding-left: 0;
    }
    .topic-set .page-tree-expanded .sidebar__table-of-contents {
        display: none;
    }
}