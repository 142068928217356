.menu-secondary {
    display: flex;
    padding: 4rem;
    gap: 3.2rem;
    background: #f8f8f8;
}
.menu-secondary-column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    gap: 1.6rem;
}
.menu-secondary-column__title {
    font-family: 'Charlie Text' !important;
    color: #101214 !important;
    font-size: 1.2rem !important;
    font-weight: 700 !important;
    line-height: 1.6rem !important;
    text-transform: uppercase !important;
}
.menu-secondary-column__items {
    display: flex;
    gap: 3.2rem;
}
@media screen and (min-width: 0) and (max-width: 768px) {
    .menu-secondary-column__items {
        flex-direction: column;
        gap: 1.2rem;
    }
}
.menu-secondary-column__items__list {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    gap: 1.2rem;
}
.menu-secondary-list-item {
    font-family: 'Charlie Text' !important;
    color: #101214 !important;
    font-size: 1.4rem !important;
    font-weight: 400 !important;
    line-height: 2.4rem !important;
}
.menu-secondary-list-item:hover {
    color: #1868db;
    text-decoration: none;
}