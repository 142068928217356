.footer {
    background-color: #F4F5F7;
}
.footer__inner-container {
    display: flex;
    margin: 0 auto;
    max-width: 1280px;
    padding: 0 20px;
}
@media screen and (min-width: 768px) {
    .footer__inner-container {
        padding: 0 70px;
    }
}
.footer__inner-content {
    min-height: 120px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #505F79;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .footer__inner-content {
        flex-direction: column;
        align-items: flex-start;
    }
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .footer__icon {
        margin: 2rem 0;
    }
}
.footer__links ul {
    list-style-type: none;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .footer__links ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}
.footer__links ul li {
    display: inline-block;
    padding: 0.3rem;
    border-radius: 100px;
    font-weight: 500;
    font-size: 1.4rem;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .footer__links ul li {
        margin-bottom: 1rem;
    }
}
.footer__links ul li a:link, .footer__links ul li a:visited {
    text-decoration: none;
    color: inherit;
}
.footer__links ul li:not(:last-child) {
    margin-right: 2.5rem;
}
.footer__links ul li:last-of-type::before {
    content: '\00a9 ';
}