.additional-help {
    margin-top: 6rem;
    width: fit-content;
    padding: 1.5rem 6rem 1.5rem 1.5rem;
    border-radius: 5px;
    border: 0.5px rgba(23, 43, 77, 0.24) solid;
    box-shadow: 0 1px 1px 0px rgba(23, 43, 77, 0.24);
}
.additional-help .title {
    color: #172b4d;
}
.additional-help a.link {
    color: inherit;
}
.additional-help .button {
    background-color: #7bd3a3;
    font-weight: bold;
    padding: 1rem;
    width: fit-content;
}
.additional-help .subtext {
    padding: 1rem 0;
}