.author-tools__dropdown {
    position: absolute;
    top: calc(100% + 0.5rem);
    right: 0;
    width: 17rem;
}
.author-tools__link {
    height: 3.2rem;
    font-size: 1.4rem;
    margin: 0 0.5rem;
    list-style: none;
    color: #42526E;
}
.author-tools__link:hover {
    text-decoration: underline;
}
.author-tools__heading {
    font-weight: 600;
    font-size: 1.5rem;
    height: 3.2rem;
    margin: 0 0.5rem;
    list-style: none;
}
.author-tools__editor-icons {
    display: flex;
    flex-direction: row;
    position: fixed;
    right: 25px;
    top: 25px;
    z-index: 1;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .author-tools__editor-icons {
        right: 85px;
    }
}
@media screen and (min-width: 768px) and (max-width: 1094px) {
    .author-tools__editor-icons {
        right: 225px;
    }
}
.author-tools__editor-icons.parent-aligned {
    position: absolute;
    left: 0;
    flex-direction: column-reverse;
    width: 45px;
}
.author-tools__editor-logo {
    border-radius: 3px;
    cursor: pointer;
    padding: 0.5rem;
}