.preview-banner {
    background-color: #FFAB00;
    color: #172B4D;
    padding: 1rem 0;
    text-align: center;
    font-weight: 500;
    font-size: 2rem;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1040;
}