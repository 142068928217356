.products-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 3.2rem;
    row-gap: 4.8rem;
    padding: 6.4rem 3.2rem;
}
@media screen and (min-width: 768px) and (max-width: 1094px) {
    .products-grid {
        grid-template-columns: 1fr;
    }
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .products-grid {
        grid-template-columns: 1fr;
    }
}