.extra-padding {
    display: flex;
    padding: 2.5px;
}
.title-section {
    column-gap: 1.6rem;
    align-items: center;
}
.title-section .title-icon {
    display: flex;
}
.title-section .title-icon > span {
    height: 2.91rem;
}