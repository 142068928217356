.platform-notice p:not(:first-of-type) {
    margin-top: 12px;
}
.platform-notice__container {
    flex: 1 1 100%;
    margin-bottom: 3rem;
}
.platform-notice__content--bold {
    font-weight: bold;
}
.platform-notice__content--italic {
    font-style: italic;
}
.kb-article__last-published {
    padding-top: 1rem;
    font-style: italic;
    color: #6b778c;
}
.kb-article__container {
    min-width: 65.43859649%;
}
.kb-article__inner-container {
    display: flex;
    flex-wrap: wrap;
}
.kb-article__inner-container .title {
    flex: 1 1 100%;
}
.kb-article__inner-container .kb-article__body {
    flex: 1 1 65.43859649%;
    margin-top: 0;
}
.kb-article__inner-container .sidebar {
    flex: 1 1 34.47368421%;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .kb-article__inner-container {
        display: block;
    }
}
.kb-article__sub-header {
    display: flex;
    align-items: center;
    margin: 0 auto 3.2rem;
    justify-content: space-between;
    min-height: 3.2rem;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .kb-article__sub-header {
        flex-wrap: wrap;
    }
    .kb-article__sub-header .deploy-select {
        padding-top: 15px;
        width: 100%;
    }
    .kb-article__sub-header .deploy-select > div > div {
        margin: 0 2px 0 2px;
    }
}
.kb-article__body {
    min-width: 65.43859649%;
    position: relative;
}
.rtf__content {
    /* * Copy/paste of: src/renderer/less/_base.less
     * TODO (CSTE-2469): Find a way to re-use this styling instead of duplicating. */
    /* Override .rtf__content rule for p elements (or otherwise) to prevent margin at the top of table cells */
    /* * Copy/paste of static/sneaky-theme.css
     * TODO (CSTE-2469): Find a way to re-use this styling instead of duplicating. */
}
.rtf__content img {
    margin-top: 20px;
}
.rtf__content .kb-article-code-block {
    padding: 10px 0px;
}
.rtf__content .kb-article-callout {
    /* Override .rtf__content rule for p elements (or otherwise) to prevent margin at the top of the callout */
    /* Paired with max-width: 100%; on the container, this prevents overflow of the callout body
        from expanding the parent flex container since we have no direct access to SectionMessage */
}
.rtf__content .kb-article-callout > section {
    margin-top: 12px;
    padding: 12px 8px 10px 8px;
    max-width: 100%;
}
.rtf__content .kb-article-callout__heading, .rtf__content .kb-article-callout__body > :first-child {
    margin-top: 0;
}
.rtf__content .kb-article-callout p {
    overflow-wrap: break-word;
}
.rtf__content .kb-article-callout div:nth-of-type(2) {
    min-width: 0;
}
.rtf__content .confluenceTable div.content-wrapper {
    margin: 0;
    padding: 0;
}
.rtf__content .table-wrap {
    width: 100%;
    overflow-x: auto;
}
.rtf__content .table-wrap table.confluenceTable {
    margin-top: 12px;
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid #dfe1e6;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}
.rtf__content .table-wrap table.confluenceTable th.confluenceTh, .rtf__content .table-wrap table.confluenceTable td.confluenceTd {
    padding: 10px;
    border-style: solid;
    border-color: #dfe1e6;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-top-width: 0;
    border-left-width: 0;
}
.rtf__content .table-wrap table.confluenceTable tr:last-child td, .rtf__content .table-wrap table.confluenceTable tr:last-child th {
    border-bottom-width: 0;
}
.rtf__content .table-wrap table.confluenceTable tr td:last-child, .rtf__content .table-wrap table.confluenceTable tr th:last-child {
    border-right-width: 0;
}
.rtf__content .table-wrap table.confluenceTable th.confluenceTh {
    background-color: #fafbfc;
    height: 40px;
    vertical-align: middle;
    font-weight: normal;
    color: #42526e;
}
.rtf__content .table-wrap table.confluenceTable tr td > :first-child, .rtf__content .table-wrap table.confluenceTable tr th > :first-child {
    margin-top: 0px;
}
.rtf__content th, .rtf__content td {
    text-align: left;
}