.multi-select-list-filter {
    width: auto;
    margin-left: -2.25rem;
}
.multi-select-list-filter__heading {
    display: inline-flex;
    column-gap: 0.5rem;
    margin: 0 0 0.8rem 2.25rem;
}
.multi-select-list-filter__chevron {
    height: auto !important;
    padding: 0.2rem 0.4rem !important;
}
.multi-select-list-filter__title {
    align-self: center;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
}
.multi-select-list-filter__header {
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    margin: 0 0 1.2rem 2.25rem;
}
.multi-select-list-filter__options-list {
    width: 100%;
}
.multi-select-list-filter__options-list:not(:last-child) {
    margin-bottom: 1.9rem;
}
.multi-select-list-filter__options-list__container__scrollable {
    max-height: 32rem;
    overflow-y: auto;
}
.multi-select-list-filter__options-list__container__scrollable::-webkit-scrollbar {
    -webkit-appearance: none;
}
.multi-select-list-filter__options-list__container__scrollable::-webkit-scrollbar:vertical {
    width: 1.1rem;
}
.multi-select-list-filter__options-list__container__scrollable::-webkit-scrollbar-thumb {
    border-radius: 0.8rem;
    border: 0.2rem solid white;
    background-color: rgba(0, 0, 0, 0.5);
}
.multi-select-list-filter__checkbox {
    width: fit-content;
}
.multi-select-list-filter__checkbox label {
    column-gap: 1.2rem;
    padding: 0.8rem 1.6rem;
}
.multi-select-list-filter__checkbox[data-value='true'] {
    background-color: #e9f2ff;
}