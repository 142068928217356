.home-v2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}
.home-v2__browse {
    max-width: 116.8rem;
    margin: auto;
    display: flex;
    flex-direction: column;
}
.home-v2__browse--inner {
    padding: 6.4rem 0;
    margin: 0 auto;
}
.home-v2__browse--inner div[role='tablist'] div[role='tab']:not(:first-child) {
    margin-left: 20px;
}
a.home-v2__browse--see-all {
    margin: 1rem;
    display: inline-block;
    color: #0052CC;
    text-decoration: none;
    cursor: pointer;
}
.home-v2__header {
    text-align: center;
    font-family: 'Charlie Display';
    font-weight: 700;
    font-size: 3.2rem;
    line-height: 3.8rem;
    color: #101214;
    margin-bottom: 4.8rem;
    letter-spacing: 0.3px;
}
.home-v2__featured-content {
    max-width: 128rem;
    margin: -12rem auto auto auto;
}
@media screen and (min-width: 0) and (max-width: 768px) {
    .home-v2__featured-content--inner {
        padding: 0;
    }
}
.home-v2 .platform-tabs-wrapper {
    max-width: 96.8rem;
}
.home-v2 .main-content-container {
    max-width: 100%;
    margin: 0;
    padding: 0;
}
@media screen and (min-width: 768px) and (max-width: 1094px) {
    .home-v2 .main-content-container {
        padding-left: 70px;
        padding-right: 70px;
    }
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .home-v2 .main-content-container {
        padding-left: calc(20px * 2);
        padding-right: calc(20px * 2);
    }
    .home-v2 .main-content-container .products__item {
        width: 100%;
        display: inline-flex;
        flex: 0 0 100%;
    }
}