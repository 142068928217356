.anthology {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}
.anthology__sub-header {
    display: flex;
    align-items: center;
    margin: 0 auto 3.2rem;
    justify-content: space-between;
    min-height: 3.2rem;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .anthology__sub-header {
        flex-wrap: wrap;
    }
    .anthology__sub-header .deploy-select {
        padding-top: 15px;
        width: 100%;
    }
    .anthology__sub-header .deploy-select > div > div {
        margin: 0 2px 0 2px;
    }
}
.anthology__hero {
    margin-left: auto;
    margin-right: auto;
}
.anthology__hero--primary {
    width: 100%;
}
@media screen and (min-width: 1095px) {
    .anthology__hero--primary {
        width: calc(100% - 155px);
        padding-right: 5rem;
    }
}
.anthology__hero--secondary {
    display: block;
    width: 100%;
    font-family: CharlieSans;
}
.anthology__hero p {
    color: #42526E;
    font-weight: 400;
    font-size: large;
}