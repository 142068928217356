.contentful-edit {
    position: relative;
    border: 2px solid transparent;
}
.contentful-edit:hover .contentful-edit-icon {
    visibility: visible;
}
.contentful-edit.contentful-edit-icon-hover {
    border: 2px dashed #79E2F2;
    background-color: #DEEBFF;
    transition: border 1s, background-color 1s;
}
.contentful-edit-icon {
    position: absolute;
    visibility: hidden;
    bottom: 0.5rem;
    right: 0.5rem;
    cursor: pointer;
    border-radius: 0.5rem;
    border: 0.5px solid rgba(9, 30, 66, 0.04);
    box-shadow: 0 1px 1px 0 rgba(37, 56, 88, 0.25);
}
.contentful-btn {
    position: fixed;
    bottom: 25px;
    right: 25px;
    border-radius: 25px;
    padding: 15px;
    background-color: #00c7e5;
    color: white;
    box-shadow: 0 1px 1px 0 rgba(37, 56, 88, 0.25);
    cursor: pointer;
}
.contentful-btn:hover {
    background-color: #00b8d9;
}
.contentful-btn:focus {
    background-color: #00a3bf;
}