.product {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}
.product__sub-header {
    display: flex;
    align-items: center;
    margin: 0 auto 3.2rem auto;
    justify-content: space-between;
    min-height: 3.2rem;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .product__sub-header {
        flex-wrap: wrap;
    }
    .product__sub-header .deploy-select {
        padding-top: 15px;
        width: 100%;
    }
    .product__sub-header .deploy-select > div > div {
        margin: 0 2px 0 2px;
    }
}
.product__title h1 {
    font-family: 'CharlieSans';
    font-size: 3.6rem;
    line-height: 44px;
    letter-spacing: 0px;
    font-weight: 500;
}