.contact-block--inner {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4.8rem;
}
.contact-block__header {
    font-family: 'Charlie Display';
    font-weight: 700;
    font-size: 3.2rem;
    color: #101214;
}