.dropdown {
    display: flex;
    flex-direction: row;
    visibility: visible;
    position: absolute;
    box-shadow: 0 15px 50px 5px rgba(22, 22, 22, 0.1);
    min-height: 35rem;
    width: 100%;
    z-index: 3;
    top: 8.8rem;
    left: 0;
    background: -webkit-linear-gradient(left, white 50%, #F4F5F7 50%);
    /* For Safari 5.1 to 6.0 */
    background: -moz-linear-gradient(right, white 50%, #F4F5F7 50%);
    /* For Firefox 3.6 to 15 */
    background: linear-gradient(to right, white 50%, #F4F5F7 50%);
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .dropdown {
        flex: none;
        display: block;
    }
}
.dropdown__inner-container {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    width: 1140px;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .dropdown__inner-container {
        flex: none;
        display: block;
        width: 100%;
    }
}
.dropdown__mobile-list {
    width: 100%;
    color: #172b4d;
}
.dropdown__mobile-item {
    display: flex;
    justify-content: space-between;
    height: 6rem;
    align-items: center;
    padding: 0 2.5rem 0 4rem;
    border-bottom: solid 1px #DFE1E6;
    cursor: pointer;
    font-size: 1.8rem;
    font-weight: 500;
}
.dropdown__products {
    width: 100%;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .dropdown__products {
        width: 100%;
    }
}
.dropdown__products div[role='tablist'] div[role='tab']:not(:first-child) {
    margin-left: 20px;
}
li.dropdown__product-item {
    border-radius: 3px;
    flex-grow: 1;
    width: 25%;
    margin-top: 1rem;
    padding: 0 0.1rem;
    display: inline-block;
    align-items: center;
}
li.dropdown__product-item .products__link {
    margin: 1rem;
    width: 100%;
}
li.dropdown__product-item .products__icon {
    border-radius: 3px;
    height: 3rem;
    width: 3rem;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    li.dropdown__product-item {
        width: 100%;
    }
}
.dropdown__main-content-wrapper {
    background-color: white;
    position: relative;
    flex: 0 0 82%;
}
@media screen and (min-width: 768px) and (max-width: 1094px) {
    .dropdown__main-content-wrapper {
        flex: 0 0 70%;
    }
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .dropdown__main-content-wrapper {
        flex: 1;
    }
}
.dropdown__card-styling .single-line {
    display: inline-block;
}
.dropdown__card-styling .documentation-dropdown {
    background-color: inherit;
}
.dropdown__card-styling .documentation-dropdown .cards__inner {
    padding: 0;
    background-color: inherit;
}
.dropdown__card-styling .documentation-dropdown .cards__inner .cards__item {
    border-radius: 3px;
    box-shadow: none;
    padding: 0.8rem 1rem;
    display: inline-block;
    align-items: center;
    margin: 0;
    background-color: inherit;
    width: 33.3%;
    height: unset;
}
@media screen and (min-width: 768px) and (max-width: 1094px) {
    .dropdown__card-styling .documentation-dropdown .cards__inner .cards__item {
        width: 50%;
    }
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .dropdown__card-styling .documentation-dropdown .cards__inner .cards__item {
        width: 100%;
    }
}
.dropdown__card-styling .documentation-dropdown .cards__inner .cards__item .cards__link {
    padding: 0;
    min-height: 40px;
}
.dropdown__card-styling .documentation-dropdown .cards__inner .cards__item .cards__link .cards__title {
    color: #0052cc;
    font-size: 1.4rem;
    line-height: 16px;
}
.dropdown__card-styling .documentation-dropdown .cards__inner .cards__item .cards__link .cards__body {
    margin-top: 0.5rem;
    margin-bottom: 0;
    font-size: 1.2rem;
    color: #6b778c;
}
.dropdown__main-content {
    padding: 2rem;
}
@media screen and (min-width: 768px) and (max-width: 1094px) {
    .dropdown__main-content {
        padding-top: 2rem 1rem;
        width: 100%;
    }
}
.dropdown__main-content > a:link, .dropdown__main-content > a:visited {
    margin-left: 1rem;
    color: #0052CC;
    text-decoration: none;
}
.dropdown__main-content .products {
    margin-bottom: 1rem;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .dropdown__main-content .products {
        margin-top: 0;
    }
}
.dropdown__side-content {
    background-color: #F4F5F7;
    padding: 2rem 0 2rem 3rem;
    flex: 1;
}
.dropdown__side-content .helpful-topics__title {
    margin-bottom: 10px;
}
.dropdown__side-content .documentation-dropdown .cards__inner .cards__item {
    width: 100%;
    padding-left: 0px;
}
.dropdown__resources {
    padding-left: 2rem;
    padding-right: 4rem;
}
@media screen and (min-width: 768px) and (max-width: 1094px) {
    .dropdown__resources {
        padding-left: 3rem;
        padding-right: 3rem;
    }
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .dropdown__resources {
        padding: 0;
        margin-top: 0;
    }
}
.dropdown__resources.cards {
    background-color: white;
}
.dropdown__resources ul.cards__inner {
    padding: 0;
    background-color: white;
}
.dropdown__resources ul.cards__inner li.cards__item {
    border-radius: 3px;
    box-shadow: none;
    padding: 0.5rem 0.5rem;
    display: flex;
    align-items: center;
    margin: 10px 0 10px 0;
    width: 50%;
    height: unset;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .dropdown__resources ul.cards__inner li.cards__item {
        width: 100%;
    }
}
.dropdown__resources ul.cards__inner li.cards__item:hover {
    background-color: #F4F5F7;
}
.dropdown__resources ul.cards__inner li.cards__item a.cards__link {
    display: flex;
    align-items: center;
    padding: 0;
}
.dropdown__resources ul.cards__inner li.cards__item a.cards__link div.cards__icon {
    padding: 0 2rem 0 0;
}
.dropdown__resources ul.cards__inner li.cards__item a.cards__link div.cards__icon img {
    vertical-align: top;
}
.dropdown__resources ul.cards__inner li.cards__item div.cards__main .cards__title {
    color: #42526e;
    font-size: 1.4rem;
    font-weight: normal;
    padding-bottom: 0.6rem;
}
.dropdown__resources ul.cards__inner li.cards__item div.cards__main .cards__body {
    color: #6c798f;
    margin: 0;
    line-height: 11px;
    font-size: 1.2rem;
    font-weight: normal;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .dropdown__guides {
        margin-top: 0;
    }
}
.dropdown__guides ul.cards__inner {
    background-color: white;
    padding: 0;
}
.dropdown__guides ul.cards__inner li.cards__item {
    border-radius: 3px;
    box-shadow: none;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    margin: 10px 0 10px 0;
    width: 100%;
}
.dropdown__guides ul.cards__inner li.cards__item:hover {
    background-color: #F4F5F7;
}
.dropdown__guides ul.cards__inner li.cards__item a.cards__link {
    display: flex;
    align-items: center;
    padding: 0;
}
.dropdown__guides ul.cards__inner li.cards__item a.cards__link div.cards__icon {
    padding: 0 2rem 0 0;
}
.dropdown__guides ul.cards__inner li.cards__item a.cards__link div.cards__icon img {
    vertical-align: top;
}
.dropdown__guides ul.cards__inner li.cards__item div.cards__main .cards__title {
    color: #42526e;
    font-size: 1.4rem;
    font-weight: normal;
    padding-bottom: 0.6rem;
}
.dropdown__guides ul.cards__inner li.cards__item div.cards__main .cards__body {
    color: #6c798f;
    margin: 0;
    line-height: 11px;
    font-size: 1.2rem;
    font-weight: normal;
}
.nav-links__list {
    width: 100%;
    list-style: none;
    height: 100%;
}
.nav-links__list li {
    margin: 0.2rem 0;
}
.nav-links__list li.nav-links__item {
    margin: 0.8rem 0 0 0;
    height: unset;
}
.nav-links__list li a:link, .nav-links__list li a:visited {
    text-decoration: none;
    color: #5E6C84;
}
.nav-links__list li a:hover {
    text-decoration: underline;
}
.nav-links__item--icon {
    align-items: center;
    color: #5E6C84;
    display: flex;
    height: unset !important;
}
.nav-links__item--icon a > span:last-child {
    margin-left: 1rem;
}
.nav-links__item--wac {
    margin-top: 2rem !important;
}
li.nav-links__item--icon.nav-links__item--contact {
    margin-bottom: 1rem;
    height: unset !important;
}
.dropdown-v2 {
    display: flex;
    position: absolute;
    visibility: visible;
    flex-direction: row;
    right: 0;
    left: 0;
    width: 100%;
    max-width: 91.2rem;
    top: 9.2rem;
    margin-inline: auto;
    border-radius: 1.6rem;
    overflow: hidden;
    box-shadow: 0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 8px 12px 0px rgba(9, 30, 66, 0.15), 0px 0px 0px 1px rgba(188, 214, 240, 0) inset;
    z-index: 3;
}
@media screen and (min-width: 768px) and (max-width: 1094px) {
    .dropdown-v2 {
        width: 80%;
    }
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .dropdown-v2 {
        flex: none;
        display: block;
        top: 8.8rem;
        border-radius: 0;
    }
}
.dropdown-v2__inner-container {
    display: block;
    width: 100%;
}
.dropdown-v2__mobile-list {
    width: 100%;
}
.dropdown-v2__mobile-item {
    display: flex;
    justify-content: space-between;
    height: 6rem;
    align-items: center;
    padding: 0 2.5rem 0 4rem;
    border-bottom: solid 1px #DFE1E6;
    cursor: pointer;
    font-size: 1.8rem;
    font-weight: 500;
}
.dropdown-v2__main-content-wrapper {
    background-color: white;
}
@media screen and (min-width: 768px) and (max-width: 1094px) {
    .dropdown-v2__main-content {
        padding-top: 2rem 1rem;
        width: 100%;
    }
}
.dropdown-v2__main-content > a:link, .dropdown-v2__main-content > a:visited {
    margin-left: 1rem;
    color: #0052CC;
    text-decoration: none;
}