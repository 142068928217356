.search {
    position: relative;
    max-width: 56.8rem;
    width: 100%;
}
.search__input {
    border: 1px solid #b7b9be;
    border-radius: 40px;
    background: #fff;
    color: #6c6f77;
    font-family: 'Charlie Text';
    display: flex;
    width: 100%;
    height: 4.2rem;
    padding: 0.8rem 2.4rem;
    justify-content: space-between;
    align-items: center;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.4rem;
    -webkit-appearance: none;
}
.search__input::-webkit-search-cancel-button {
    display: none;
}
.search__icon {
    position: absolute;
    top: calc(50% - 12px);
    right: 2.4rem;
    color: #253858;
    cursor: pointer;
}