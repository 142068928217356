.kb-home__container {
    min-width: 65.43859649%;
}
.kb-home .search {
    margin: 1rem 0 5rem;
}
.kb-home__inner-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}
.kb-home__sub-header {
    display: flex;
    margin: 0 auto 3.2rem;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .kb-home__sub-header {
        flex-wrap: wrap;
    }
}
.kb-home__search {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.kb-home__search-bar {
    margin-top: 1rem;
    margin-bottom: 5rem;
    width: 60%;
    border: 2px solid #dfe1e6;
    border-radius: 5px;
}
.kb-home__divider {
    border: none;
    height: 2px;
    border-radius: 1px;
    margin: 10px 0px 10px;
    background-color: #ebecf0;
}
.kb-home input {
    background-color: #fafbfc;
}