.not-found .main-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.not-found h1 {
    font-size: 7.8rem;
    line-height: 1.2;
}
.not-found__search {
    margin-top: 2rem;
    max-width: 56.8rem;
    width: 100%;
}