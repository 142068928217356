.version-listing__title {
    color: #A5ADBA;
    font-weight: 500;
}
.version-listing__wrapper {
    padding: 10px 0 0 0;
}
.version-listing__background {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Noto Sans', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.006em;
    line-height: 20px;
    color: #172B4D;
    background-color: #F4F5F7;
    width: 'fit-content';
    padding: 0px 5px;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
}
.version-listing__text {
    padding-left: 5px;
}