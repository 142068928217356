/* ====================================================
Fonts
======================================================= */
@font-face {
    font-family: 'Charlie Display';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: url(//assets.ctfassets.net/rz1oowkt5gyp/6nq3flI8NfgICrz3jZjLyD/81918253d594b7bb77eb898a468d4328/Charlie_Display-Regular.woff2) format('woff2'), url(//assets.ctfassets.net/rz1oowkt5gyp/2GTE85R9nfLI58KxINGukf/9d35203b2fe1d4a89031954f118b6c9f/Charlie_Display-Regular.woff) format('woff')
}
@font-face {
    font-family: 'Charlie Display';
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src: url(//assets.ctfassets.net/rz1oowkt5gyp/3KRSiun937K2pXBPYiUwgB/17f578e7699c12924de360e09c018c7c/Charlie_Display-Semibold.woff2) format('woff2'), url(//assets.ctfassets.net/rz1oowkt5gyp/oQSiwcfMwyNafeeJ3DIku/364b1a7409856a36ab05c2b43a1f9bde/Charlie_Display-Semibold.woff) format('woff')
}
@font-face {
    font-family: 'Charlie Display';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: url(//assets.ctfassets.net/rz1oowkt5gyp/55uCHq9XzIzIG8yO7WRqfM/b62779fff17f06743e490f6d98a2001a/Charlie_Display-Bold.woff2) format('woff2'), url(//assets.ctfassets.net/rz1oowkt5gyp/7lMBcxnaE1rq1n9am4LaWV/b17259435611992f2a6ffe48ca213f84/Charlie_Display-Bold.woff) format('woff')
}
@font-face {
    font-family: 'Charlie Display';
    font-style: normal;
    font-display: swap;
    font-weight: 800;
    src: url(//assets.ctfassets.net/rz1oowkt5gyp/2QtjA2B9aCIa8HvyZcNWqL/886e02c2b15732bc62dc9550078d991c/Charlie_Display-Black.woff2) format('woff2'), url(//assets.ctfassets.net/rz1oowkt5gyp/7mOCTVLMRdYynIubPDry0Y/85324db781253d544cf91d5c9078a820/Charlie_Display-Black.woff) format('woff')
}
@font-face {
    font-family: 'Charlie Text';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: url(//assets.ctfassets.net/rz1oowkt5gyp/41EruTPEOU9hcBwH94UVuZ/73ac40404d750278b451bf2adf186b1b/Charlie_Text-Regular.woff2) format('woff2'), url(//assets.ctfassets.net/rz1oowkt5gyp/6h4LgfzSDGYFyOvrNTr21p/99d04355f5ef0b92b882013bb0857cf9/Charlie_Text-Regular.woff) format('woff')
}
@font-face {
    font-family: 'Charlie Text';
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src: url(//assets.ctfassets.net/rz1oowkt5gyp/2d4nxzJwX8hfIqUN71D4OD/5e2c0c2a3677d935e91dd46635d5149d/Charlie_Text-Semibold.woff2) format('woff2'), url(//assets.ctfassets.net/rz1oowkt5gyp/4Y73c86mEJg3PJBgQe0L8t/a5d27d39320e9703ed48dd3bdb308408/Charlie_Text-Semibold.woff) format('woff')
}
@font-face {
    font-family: 'Charlie Text';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: url(//assets.ctfassets.net/rz1oowkt5gyp/6fvn66mVrRz4Yw3gQoPaJJ/458e6a69f0036d1e566e028fcbc26a50/Charlie_Text-Bold.woff2) format('woff2'), url(//assets.ctfassets.net/rz1oowkt5gyp/3JvzHM3Atfe3nL2nvb4AqL/5bb9a76be7a4b053dbb276d9ffc6654a/Charlie_Text-Bold.woff) format('woff')
}
@font-face {
    font-family: 'Charlie Text';
    font-style: normal;
    font-display: swap;
    font-weight: 800;
    src: url(//assets.ctfassets.net/rz1oowkt5gyp/2AZXOOAKgaXV55fqGI098Y/bb085d34b97c542aedea66d5e4d9bc95/Charlie_Text-Black.woff2) format('woff2'), url(//assets.ctfassets.net/rz1oowkt5gyp/3czBeZah9GZg8dI5cMd2er/7a13c24355717ba63712c3550921339f/Charlie_Text-Black.woff) format('woff')
}
/* ====================================================
Colors
======================================================= */